/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import sidekickInit from '../../../utils/sidekick/init';
import calculateCardStyle from '../../../utils/calculateCardStyle';
import filterByLocale from '../../../utils/filterByLocale';
import ElementCardListPropTypes from '../ElementCardListPropTypes';
import ContentModule from '../../ContentModule';
import ElementCard from '../../ElementCard';
import ElementLink from '../../ElementLink';

const variants = {
  show: {
    y: 0,
    opacity: 1
  },
  hidden: {
    y: 100,
    opacity: 0,
    transition: {
      y: {}
    }
  }
};

const ThreePerRow = (props) => {
  const { lang } = useTranslation();
  const { titleModule, cardStyle, cards, link, anchor } = props;
  const cardsInLocale = React.useMemo(() => filterByLocale(cards, lang), [cards, lang]);
  const cardType = cardsInLocale?.length ? cardsInLocale[0]?._contentTypeId : '';

  return cardsInLocale?.length > 0 ? (
    <div
      data-testid="ElementCardList-ThreePerRow"
      className={cx(
        'cardList-threePerRow',
        titleModule && cardType !== 'aaShopifyProduct' ? 'mt-4 pt-5' : ''
      )}
      id={anchor.toLowerCase()}
      {...sidekickInit(props).sidekicker('Card List')}>
      {titleModule && (
        <ContentModule contentTypeId={titleModule._contentTypeId} fields={titleModule} />
      )}
      <div
        className={cx(
          'container',
          titleModule && cardType !== 'aaShopifyProduct' && 'mt-4 pt-3 pb-5'
        )}>
        <div
          transition={{ type: 'spring', delayChildren: 0.3, mass: 2 }}
          variants={{
            show: {
              transition: {
                staggerChildren: 0.15,
                elayChildren: 0.15
              }
            },
            hidden: {
              transition: {
                staggerChildren: 0.15
              }
            }
          }}
          data-testid="CardList-ThreePerRow-cards"
          className={`card-grid row mx-auto ${cards?.length === 2 ? 'only-two' : ''}`}>
          {cardsInLocale &&
            cardsInLocale.map((card) => {
              return (
                <div variants={variants} key={card._id} data-testid="CardList-ThreePerRow-card">
                  <ElementCard {...card} cardStyle={calculateCardStyle(cardStyle, card)} />
                </div>
              );
            })}
        </div>
        {link && (
          <div className="mt-4">
            <ElementLink {...link} {...sidekickInit(link).sidekicker('Element Link')} />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

ThreePerRow.propTypes = ElementCardListPropTypes;
ThreePerRow.defaultProps = {
  titleModule: null,
  cards: null,
  pullCardsAutomatically: null,
  numberOfCardsToPull: null,
  link: null,
  anchor: ''
};

export default ThreePerRow;
