export default function calculateCardStyle(listCardStyle, card) {
  if (!listCardStyle) {
    return card?.cardStyle;
  }

  if (card?.shouldOverrideListStyle) {
    return card?.cardStyle;
  }

  return listCardStyle;
}
