const startCase = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const sidekick = (contentId, fieldName, contentTypeId, displayText) => {
  if (!contentId && !displayText) return null;

  const getDisplayVal = () => {
    if (displayText) return displayText;
    if (fieldName) return startCase(fieldName);
    if (contentTypeId) return startCase(contentTypeId);
    return 'Item';
  };

  const displayVal = getDisplayVal();

  return {
    ...(contentId && { 'data-csk-entry-id': contentId }),
    ...(fieldName && { 'data-csk-entry-field': fieldName }),
    ...(contentTypeId && { 'data-csk-entry-type': contentTypeId }),
    ...(displayVal && { 'data-csk-entry-display-text': displayVal })
  };
};

const sidekickInit = ({ _id, _contentTypeId, id, contentTypeId } = {}) => {
  const contentType = _contentTypeId || contentTypeId;
  return {
    sidekick,
    sidekicker: (displayText = contentType, fieldname = null) =>
      sidekick(_id || id, fieldname, contentType, displayText)
  };
};

export default sidekickInit;
