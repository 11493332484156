'use client';

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ContextComposer from 'react-context-composer';
import { useContentModuleContext } from './ContentModuleContext';
import { getProviders } from '../../utils/getMUITheme';

export const ContentModulePropTypes = {
  _contentTypeId: PropTypes.string,
  contentTypeId: PropTypes.string,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

function ContentModule({ _contentTypeId, contentTypeId, variant: aVariant, fields, ...props }) {
  const type = _contentTypeId || contentTypeId;
  const variant = fields?.variant || aVariant;
  const contentType = variant ? `${type}:${variant}` : type;

  const contentMapping = useContentModuleContext();
  const Main = React.useMemo(() => contentMapping[contentType], [contentMapping, contentType]);
  const providers = React.useMemo(() => getProviders(fields), [fields]);

  if (!Main) {
    // eslint-disable-next-line no-console
    console.info(
      `Did not find mapping for Content Type ${contentType}. Please add an override to contentMapping.ts`
    );

    return null;
  }

  return (
    <ContextComposer contexts={providers}>
      <Main key={props.id} _contentTypeId={_contentTypeId} {...fields} {...props} />
    </ContextComposer>
  );
}

ContentModule.propTypes = ContentModulePropTypes;
ContentModule.defaultProps = {
  variant: ''
};

export default ContentModule;
