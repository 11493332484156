import PropTypes from 'prop-types';
import { CardPropTypes } from '../Card';
import { ElementLinkPropTypes } from '../ElementLink';
import PageBlogPropTypes, { TagsTopicPropTypes } from '../PageBlog/PageBlog.types';
import PageNewsPropTypes from '../PageNews/PageNews.types';
import { PagePersonPropTypes } from '../PagePerson';
import PageRecipePropTypes from '../PageRecipe/PageRecipe.types';

export default {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  titleModule: PropTypes.object,
  cards: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CardPropTypes),
      PropTypes.shape(PageBlogPropTypes),
      PropTypes.shape(PagePersonPropTypes),
      PropTypes.shape(PageNewsPropTypes),
      PropTypes.shape(PageRecipePropTypes)
    ])
  ),
  categories: PropTypes.arrayOf(TagsTopicPropTypes),
  pullCardsAutomatically: PropTypes.string,
  numberOfCardsToPull: PropTypes.number,
  cardStyle: PropTypes.oneOf([
    'EmbeddedVideo',
    'Promo',
    'Save / Share',
    'Info',
    'Profile',
    'Icon',
    'Media',
    'Round',
    'Timeline',
    'News',
    'Blog Post',
    'Product / Medium'
  ]).isRequired,
  listStyle: PropTypes.oneOf([
    '1 per row',
    '3 per row',
    '4 per row',
    '2-1 unit',
    '3-1-3 rows',
    'Accordion Grouped by Year',
    'Carousel',
    'Timeline',
    'Showcase'
  ]).isRequired,
  colorScheme: PropTypes.oneOf(['Default', 'Dark', 'Light']),
  link: PropTypes.shape(ElementLinkPropTypes),
  anchor: PropTypes.string,
  rows: PropTypes.number,
  showFilter: PropTypes.bool,
  showPagination: PropTypes.bool,
  carouselStyle: PropTypes.oneOf([
    'Horizontal - arrows on the bottom',
    'Horizontal - arrows on the side'
  ])
};
