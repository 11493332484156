import React from 'react';
import merge from 'lodash/merge';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';

const getMUITheme = (theme) => {
  if (Array.isArray(theme)) {
    return merge({}, ...theme);
  }
  return null;
};

export const getProviders = ({ theme } = {}) => {
  const providers = [];

  const muiTheme = getMUITheme(theme);
  if (muiTheme) {
    // TODO: need to call createTheme
    providers.push(<MuiThemeProvider theme={muiTheme} />);
  } else if (theme && typeof theme !== 'string') {
    providers.push(<ThemeProvider theme={theme} />);
  }

  return providers.filter((x) => !!x);
};

export default getMUITheme;
