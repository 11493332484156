/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { ImagePropTypes } from '../Image';
import { ElementLinkPropTypes } from '../ElementLink';

const IngredientPropTypes = {
  step: PropTypes.number.isRequired,
  stepId: PropTypes.string.isRequired,
  ingredient: PropTypes.string.isRequired,
  imperialMeasure: PropTypes.string.isRequired,
  imperialQuantity: PropTypes.number.isRequired,
  metricMeasure: PropTypes.string.isRequired,
  metricQuantity: PropTypes.number.isRequired
};

const StepsPropTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

const DetailPropTypes = {
  _id: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  authorImage: PropTypes.shape(ImagePropTypes),
  authorName: PropTypes.string.isRequired,
  cta: PropTypes.shape(ElementLinkPropTypes),
  cookTime: PropTypes.number.isRequired,
  difficulty: PropTypes.string.isRequired,
  relatedItemsOn: PropTypes.bool,
  prepTime: PropTypes.number.isRequired,
  description: PropTypes.object.isRequired,
  image: PropTypes.shape(ImagePropTypes).isRequired,
  productImage: PropTypes.shape(ImagePropTypes),
  productImageMultipleImages: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  ingredients: PropTypes.arrayOf(PropTypes.shape(IngredientPropTypes)).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(StepsPropTypes)).isRequired,
  title: PropTypes.string.isRequired,
  servings: PropTypes.number.isRequired
};

const PageRecipePropTypes = {
  ...DetailPropTypes,
  similarRecipes: PropTypes.arrayOf(PropTypes.shape(DetailPropTypes)),
  seo: PropTypes.object
};

export default PageRecipePropTypes;
